import React from "react";
import { LinkedinIcon, TwitterIconOutline } from "~/components/icons";
import metrics from "~/utils/metrics";
import { Button, DialogFooter } from "@mindstonehq/ui";
import * as Sentry from "@sentry/react";

interface AchievementCompleteDialogProps {
  id: string;
  name: string;
  imageURL: string;
  awardedOn?: string;
  shareURL?: string;
  grantId?: string;
}

export default function AchievementCompleteDialog({
  id,
  name,
  imageURL,
  awardedOn,
  shareURL,
  grantId,
}: AchievementCompleteDialogProps) {
  const handleLinkedin = () => {
    if (!awardedOn || !shareURL || shareURL === "") {
      Sentry.captureException(new Error("Cannot share badge to LinkedIn"), {
        tags: {
          name: name,
          grantId: grantId,
        },
        extra: {
          shareURL: shareURL,
          awardedOn: awardedOn,
        },
      });
      return;
    }

    metrics.track("did_tap_share_badge", {
      type: "linkedin",
      url: shareURL,
    });

    const mindstoneLinkedInOrgId = "70887821";

    const awardDateObj = new Date(awardedOn);
    const issueYear = awardDateObj.getUTCFullYear();
    const issueMonth = awardDateObj.getUTCMonth() + 1; // getUTCMonth() returns 0-11

    // Calculate expiration date (one year later)
    const expirationDate = new Date(awardDateObj);
    expirationDate.setUTCFullYear(expirationDate.getUTCFullYear() + 1);
    const expirationYear = expirationDate.getUTCFullYear();
    const expirationMonth = expirationDate.getUTCMonth() + 1;

    const linkedinParams = new URLSearchParams({
      startTask: id,
      name,
      organizationId: mindstoneLinkedInOrgId,
      issueYear: issueYear.toString(),
      issueMonth: issueMonth.toString(),
      expirationYear: expirationYear.toString(),
      expirationMonth: expirationMonth.toString(),
      certUrl: shareURL,
      certId: grantId ?? "",
    });

    const linkedinUrl = `https://www.linkedin.com/profile/add?${linkedinParams.toString()}`;

    window.open(linkedinUrl, "_blank");
  };

  const handleTwitter = () => {
    if (!awardedOn || !shareURL || shareURL === "") {
      Sentry.captureException(new Error("Cannot share badge to twitter"), {
        tags: {
          name: name,
          grantId: grantId,
        },
        extra: {
          shareURL: shareURL,
          awardedOn: awardedOn,
        },
      });
      return;
    }

    metrics.track("did_tap_share_badge", {
      type: "twitter",
      url: shareURL,
    });
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        shareURL,
      )}&text=${encodeURIComponent(
        "Just earned this badge on @MindstoneHQ 🌟 Ready to take on new challenges and innovate! #MindstoneAIMastery #TechSkills #Innovation #ContinuousLearning",
      )}`,
      "_blank",
    );
  };

  return (
    <>
      <div className="flex flex-col items-center py-6">
        <img className="h-20 w-20" src={imageURL} alt={name} />
        <div className="t-body-lg-bold mb-2 mt-3">Congratulations!</div>
        {name && (
          <div className="t-body-md mb-2">
            You have mastered <strong>{name}</strong>.
          </div>
        )}
      </div>
      <DialogFooter className="flex sm:justify-center">
        <Button onClick={handleTwitter} variant="outline" className="gap-2">
          <TwitterIconOutline className="h-5 w-5" />
          <span>Share on Twitter</span>
        </Button>
        <Button
          onClick={handleLinkedin}
          className="btn-tertiary-dark-md min-w-[120px]"
          variant="secondary"
        >
          <LinkedinIcon className="mb-1 text-background" />
          <span>Share on LinkedIn</span>
        </Button>
      </DialogFooter>
    </>
  );
}
