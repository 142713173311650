import React from "react";

export function LinkedinIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 6.01939H4.98298V19.0468H1V6.01939ZM16.2809 6.82402C17.0723 7.25827 17.7234 7.91603 18.234 8.79729C18.7447 9.67856 19 10.579 19 11.4986V19.0468H14.9787V11.4986C14.9787 11.2942 14.9021 11.0643 14.7489 10.8089C14.5957 10.5534 14.4553 10.3874 14.3277 10.3108C14.1234 10.1831 13.8426 10.1192 13.4851 10.1192C13.1277 10.1192 12.8468 10.1703 12.6426 10.2725L10.9957 11.0771V19.0468H6.97447V6.01939H10.9957V6.63244C11.7872 6.27483 12.6809 6.10879 13.6766 6.13434C14.6723 6.15988 15.5404 6.38978 16.2809 6.82402ZM2.99149 1C3.55319 1 4.02553 1.19158 4.40851 1.57474C4.79149 1.9579 4.98298 2.43046 4.98298 2.99243C4.98298 3.5544 4.79149 4.03335 4.40851 4.42928C4.02553 4.82521 3.55319 5.02317 2.99149 5.02317C2.42979 5.02317 1.95745 4.82521 1.57447 4.42928C1.19149 4.03335 1 3.5544 1 2.99243C1 2.43046 1.19149 1.9579 1.57447 1.57474C1.95745 1.19158 2.42979 1 2.99149 1Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LinkedinIcon;
