import React from "react";

export function TwitterIconOutline(props: React.ComponentProps<"svg">) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 4.65261C18.4677 5.3871 17.8508 5.98387 17.1492 6.44293V6.92494C17.1492 8.07258 16.9194 9.23744 16.4597 10.4195C16 11.6016 15.3286 12.6689 14.4456 13.6214C13.5625 14.574 12.4677 15.3544 11.1613 15.9626C9.85484 16.5709 8.35484 16.875 6.66129 16.875C5.59677 16.875 4.59274 16.7373 3.64919 16.4618C2.70565 16.1864 1.82258 15.7962 1 15.2913C1.14516 15.3142 1.29032 15.3314 1.43548 15.3429C1.58065 15.3544 1.72581 15.3601 1.87097 15.3601C2.74194 15.3601 3.57056 15.2224 4.35685 14.947C5.14315 14.6715 5.85081 14.3043 6.47984 13.8452C5.65726 13.8223 4.9375 13.587 4.32056 13.1394C3.70363 12.6918 3.27419 12.1123 3.03226 11.4007C3.15323 11.4467 3.26815 11.4696 3.37702 11.4696H3.72177C4.06048 11.4696 4.375 11.4352 4.66532 11.3663C3.81855 11.1827 3.11694 10.7753 2.56048 10.1441C2.00403 9.51287 1.72581 8.77264 1.72581 7.92339V7.88896C1.99194 8.00372 2.25806 8.10127 2.52419 8.18161C2.79032 8.26194 3.08065 8.30211 3.39516 8.30211C2.91129 7.98077 2.51815 7.56762 2.21573 7.06266C1.91331 6.55769 1.7621 6.00682 1.7621 5.41005C1.7621 4.76737 1.93145 4.18207 2.27016 3.65416C3.18952 4.70999 4.30242 5.56498 5.60887 6.21914C6.91532 6.87329 8.33064 7.2348 9.85484 7.30366C9.78226 7.02823 9.74597 6.76427 9.74597 6.51179C9.74597 6.02978 9.84274 5.57646 10.0363 5.15183C10.2298 4.7272 10.496 4.35422 10.8347 4.03288C11.1734 3.71154 11.5665 3.45906 12.0141 3.27543C12.4617 3.09181 12.9395 3 13.4476 3C13.9798 3 14.4758 3.09755 14.9355 3.29265C15.3952 3.48775 15.7944 3.75744 16.1331 4.10174C16.5685 4.03288 16.9798 3.92385 17.3669 3.77466C17.754 3.62547 18.129 3.44758 18.4919 3.241C18.2016 4.09026 17.6573 4.74442 16.8589 5.20347C17.6331 5.08871 18.3468 4.90509 19 4.65261Z"
        fill="#27272A"
      />
    </svg>
  );
}
